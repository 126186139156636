import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import PageBackground from "./pageBackground"

import Header from "./header"
import "../css/layout.css"
import Footer from "./footer";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
      <PageBackground>
        <div className = "page-wrapper">
          <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
          <main className="content-wrapper">
            {children}
          </main>
          <Footer/>
        </div>
      </PageBackground>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
