import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"

import "../css/layout.css"

  const PageBackground = ({ children }) => {
    const imageQuery = useStaticQuery(
      graphql`
        query BackgroundImage {
          backgroundImage: file(relativePath: { eq: "background.JPG"}) {
            childImageSharp {
              fluid(maxWidth: 2000){
                ...GatsbyImageSharpFluid
              }   
            }
          }
        }
      ` 
  )

  const imageData = imageQuery.backgroundImage.childImageSharp.fluid

  return (
      <BackgroundImage
          className="background-image"
          fluid={imageData}
      >
          {children}
      </BackgroundImage>
  )
}

export default PageBackground